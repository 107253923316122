import React from "react";

import Layout from "../../components/Layout";
import Teachers from "../../epics/teachers/Teachers";

const TeachersPage = () => (
  <Layout>
    {/*<Teachers />*/}
  </Layout>
);

export default TeachersPage;
