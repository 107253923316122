import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import IO from "../../../components/pose/IO";
import { UpPose } from "../../../components/pose/Poses";
import color from "../../../common/styles/color";
import MEDIA from "../../../common/styles/media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StartButton from "../../../components/StartButton";

const Research = ({ data }) => (
  <Wrapper>
    <ResearchContainer>
      <Container>
        <Grid>
          <Column>
            <Subtitle>
              <FontAwesomeIcon icon={data.research.icon} color={color.green} />{" "}
              {data.research.subtitle}
            </Subtitle>
            <Title>{data.research.title}</Title>
            <Description>{data.research.description}</Description>
            <ButtonContainer>
              <StartButton>Learn more</StartButton>
            </ButtonContainer>
          </Column>
        </Grid>
      </Container>
    </ResearchContainer>
  </Wrapper>
);

Research.propTypes = {
  data: PropTypes.object.isRequired
};

const ResearchWithQuery = props => (
  <StaticQuery
    query={graphql`
      query TeachersResearchQuery {
        teachersYaml {
          research {
            icon
            subtitle
            title
            description
          }
        }
      }
    `}
    render={({ teachersYaml }) => <Research data={teachersYaml} {...props} />}
  />
);

export default ResearchWithQuery;

const Wrapper = styled.div`
  background: #222637;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  overflow: hidden;
  z-index: 100;
  display: flex;
}
`;

const ResearchContainer = styled.div`
  background: linear-gradient(263deg, #67e68b, #29d6e6);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  display: flex;

  //overflow: hidden;
  //z-index: 100;
  //margin: auto auto;
}
`;

const Container = styled.div`
  background: ${color.white};
  justify-content: center;
  display: flex;
  padding: 20px 0 80px 0;
  overflow: hidden;
  z-index: 1000;
  //border-radius: 20px;
  ${MEDIA.TABLET`
    padding: 50px 0 80px 0;
  `};
`;

const Grid = styled.div`
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr;
  //grid-gap: 4rem;
  width: 100%;
  max-width: 1200px;
`;

const Column = styled.div`
  text-align: center;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  flex-flow: column;
  h2 {
    margin-bottom: 25px;
  }
  a {
    margin-top: 40px;
  }
`;

const ButtonContainer = styled.div`
  //margin-top: 20px;
`;

export const Subtitle = styled.h2`
  margin: 10px 0;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.4px;
  svg {
    margin-right: 3px;
  }
`;

export const Title = styled.h3`
  margin: 10px 0 18px 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.5px;
  ${MEDIA.DESKTOP`
    font-size: 26px;
    letter-spacing: 1px;
  `};
  ${MEDIA.MOBILE`
    font-size: 28px;
    letter-spacing: 1px;
  `};
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-weight: 500;
  ${MEDIA.DESKTOP`
    font-size: 18px;
  `};
  ${MEDIA.MOBILE`
    font-size: 17px;
  `};
`;
