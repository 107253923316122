import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import color from "../../../common/styles/color";
import MEDIA from "../../../common/styles/media";
import { UpPose } from "../../../components/pose/Poses";
import IO from "../../../components/pose/IO";

import "./Hero.css";
import WhiteButton from "../../../components/WhiteButton";

// The learning workflow (re)defined


const Hero = ({ data }) => {
  return (
    <IO>
      {({ isVisible, hasBeenVisible }) => (
        <Wrapper>
          <div className="TeacherBackground">
            <UpPose pose={isVisible || hasBeenVisible ? "visible" : "hidden"}>
              <div className="TopBannerText">
                <div className="TopBannerTitle">
                  A platform for networked
                  <span className="Strong">learning</span>
                </div>
                <div className="TopBannerDescription">
                  Learn, collaborate, game, and get organised — all in one tool.{" "}
                </div>
                <div className="TopBannerButtonContainer">
                  <WhiteButton href="localhost:3000">Join Beta</WhiteButton>
                </div>
              </div>
            </UpPose>
          </div>

          <div className="BackgroundTwinkle">
            <div id="stars"></div>
            <div id="stars2"></div>
          </div>
        </Wrapper>
      )}
    </IO>
  );
};

const HeroWithQuery = props => (
  <StaticQuery
    query={graphql`
      query TeachersHeroQuery {
        teachersYaml {
          header {
            title
            highlight
            description
            action
            comment
            image {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
              extension
            }
          }
        }
      }
    `}
    render={({ teachersYaml }) => <Hero data={teachersYaml} {...props} />}
  />
);

export default HeroWithQuery;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bototm: 20px;
  //background: #000;
`;

const Container = styled.div`
  display: grid;
  min-height: 600px;
  max-height: 65px;
  height: 80vh;
  justify-content: space-between;
  grid-template-columns: 550px 3fr;
  grid-gap: 4rem;
  overflow: hidden;
  margin-bottom: -100px;
  ${MEDIA.MOBILE`
    grid-template-columns: 100%;
    min-height: 600px;
  `};
`;

const Text = styled.div`
  margin: 14% 10% 10% 18%;
  ${MEDIA.TABLET`
    margin: 10vw 10vw 10vw 12vw;
  `};
  ${MEDIA.MOBILE`
    margin: 12vw 10vw 10vw 10vw;
  `};
`;
const Title = styled.h1`
  color: ${color.white};
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  ${MEDIA.MOBILE`
    font-size: 65px;
    letter-spacing: -2.8px;
  `};
`;

const Highlight = styled.h1`
  color: ${color.purple};
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  ${MEDIA.MOBILE`
    font-size: 65px;
    letter-spacing: -2.8px;
  `};
`;

const Description = styled.div`
  margin: 10px 0px 20px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-size: 22px;
  color: ${color.white};
  ${MEDIA.MOBILE`
    font-size: 18px;
    letter-spacing: 0.5px;
  `};
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
  }
  a {
    font-size: 22px;
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
  }
  ${MEDIA.MOBILE`
      a {
      font-size: 15px;
    }
  `}
`;

const Comment = styled.p`
  margin-top: 20px;
  font-size: 15px;
  color: ${color.blackLight};
  letter-spacing: -0.4px;
  ${MEDIA.MOBILE`
    font-size: 13px;
    letter-spacing: -0.3px;
  `};
`;

const Figure = styled.figure`
  width: 500px;
  //min-width: 980px;
  margin-top: 20px;
  ${MEDIA.TABLET`
    display: none;
  `};
`;
